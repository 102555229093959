<template>
  <v-container>
    <!-- BOC:[breadcrumbs] -->
    <ABreadcrumb :items="breadcrumbs"></ABreadcrumb>
    <!-- EOC -->
    <AError
      v-if="api.isError"
      :api="api"
      :callbackReset="() => (api.arror = false)"
    ></AError>
    <v-card class="mx-auto pa-3" max-width="800" outlined min-height="200">
      <v-row>
        <v-col
          ><h2>Classroom Info</h2></v-col
        >
        <v-col cols="12" sm="6">
          <div class="d-flex justify-center justify-sm-end">
            <div class="pr-2">
              <v-btn width="120px" v-if="classroom && classroom.isActive"  rounded outlined color="error" :to="{name:'PageEducatorInstitutionClassroomInactive',params:{id:this.$route.params.id,parentId:this.$route.params.parentId}}"> Deactivate</v-btn>
              <v-btn  width="120px" v-else-if="classroom && !classroom.isActive" :loading="editApi.isLoading"  @click="changeStatus(true)" rounded outlined color="success">Reactivate</v-btn>
            </div>
            <div>
              <v-btn rounded color="primary" :to="{name:'PageEducatorInstitutionClassroomEdit',params:{id:this.$route.params.id,parentId:this.$route.params.parentId}}"> Edit </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-card-title></v-card-title>
      <ALoader :isLoading="api.isLoading"></ALoader>
      <Read v-if="!api.isLoading && classroom" :headers="headers" :data="classroom" />
    </v-card>
  </v-container>
</template>
    
    <script>
import { mapState } from "vuex";
import Read from "../../components/Datatable/Read.vue";
export default {
  components: {
    Read
  },
  computed: {
    size() {
      const size = { xs: "small", sm: "small", lg: "large", xl: "x-large" }[
        this.$vuetify.breakpoint.name
      ];
      return size ? { [size]: true } : {};
    },
    ...mapState({
      auth: (state) => state.auth.data,
    }),
  },
  // computed: mapState({
  //     auth: (state) => state.auth.data,
  //   }),
  props: [
    //
  ],
  data: () => ({
    //BOC:[breadcrumbs]
    breadcrumbs: [],
    //EOC
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    editApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    gameUrl: null,
    totalPage: 0,
    search: "",
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    headers: [
      // {
      //   text: "Id",
      //   align: "start",
      //   sortable: false,
      //   value: "id",
      // },
      { text: "Name", value: "name" },
      // { text: "Month", value: "month" },
      { text: "School Year", value: "schoolYear" },
      { text: "First School Date", value: "firstSchoolDate" },
      { text: "Activation", value: "isActive" },
      { text: "Last Update", value: "timestampUpdated" },
    ],
    data: [],
    classroom: {},
  }),
  created() {
    //BOC:[breadcrumbs]
    this.breadcrumbs = [
      {
        text: "Dashboard",
        to: { name: "PageEducatorDashboard" },
        exact: true,
      },
      {
        text: "My Institution",
        to: {
          name: "PageEducatorInstitutionDashboard",
          params: { id: this.$route.params.parentId },
        },
        exact: true,
      },
      {
        text: "Classrooms",
        to: {
          name: "PageEducatorInstitutionClassroom",
          params: { id: this.$route.params.parentId },
        },
        exact: true,
      },
      {
        text: "Classroom",
        to: { name: "PageEducatorInstitutionClassroomDashboard" },
        exact: true,
      },
      {
        text: "Info",
        to: { name: "PageEducatorInstitutionClassroomRead" },
        exact: true,
      },
    ];
    //EOC
    this.api.method = "GET";
    this.api.url =
      this.$api.servers.sso +
      "/api/v1/" +
      this.$_getLocale() +
      "/educator/institution/"+this.$route.params.parentId+"/classroom/" +
      this.$route.params.id;

    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };

    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };

    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.classroom = resp;
      this.data.push(this.classroom);
    };

    this.editApi.url =
      this.$api.servers.sso +
      "/api/v1/" +
      this.$_getLocale() +
      "/educator/institution/"+this.$route.params.parentId+"/classroom/changeStatus";

    this.editApi.callbackReset = () => {
      this.editApi.isLoading = true;
      this.editApi.isError = false;
    };

    this.editApi.callbackError = (e) => {
      this.editApi.isLoading = false;
      this.editApi.isError = true;
      this.editApi.error = e;
    };

    this.editApi.callbackSuccess = () => {
      this.editApi.isLoading = false;
      this.$api.fetch(this.api);
    };


    //EOC
  },
  mounted() {
    this.$api.fetch(this.api);
  },
  methods: {
    changeStatus(status){
      this.editApi.params = {id: this.$route.params.id, isActive:status}
      this.$api.fetch(this.editApi);
    }
  },
};
</script>
<style scoped>
.menu-item {
  border: 1px solid black;
  margin: 3px;
}
.form-content {
  width: 600px !important;
}
</style>